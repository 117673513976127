<template>
  <div>
    <v-container fluid>
      <v-row no-gutter>
        <v-col cols="8" xs="8">
          <v-sheet color="accent rounded-xl pl-3">
            <v-text-field v-model="orgUserName" filled rounded dense background-color="#ffffff"
              placeholder="Buscar permiso" hide-details="auto" class="rounded-search" clearable
              v-on:keyup.enter="filtrarOrganizationUser()" @click:clear="(orgUserName = ''), filtrarOrganizationUser()">
              <template v-slot:prepend>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on">
                      <v-icon>fa fa-search</v-icon>
                    </v-btn>
                  </template>
                  Da clic para buscar
                </v-tooltip>
              </template>
            </v-text-field>
          </v-sheet>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" xs="2" class="d-flex justify-end" v-if="this.boolUserPermission === false">
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ }">
              <v-btn @click="abrirModal()" color="accent" :small="$vuetify.breakpoint.xsOnly"
                :class="$vuetify.breakpoint.smAndUp ? 'rounded-xl' : ''" :fab="$vuetify.breakpoint.smAndDown" :disabled="!user.permissions.Permission ||
              !user.permissions.Permission.create
              ">
                <v-icon v-if="$vuetify.breakpoint.smAndDown"> mdi-plus </v-icon>
                <span v-else class="font-weight-bold black--text">+ Crear permiso</span>
              </v-btn>
            </template>
          </v-dialog>
        </v-col>

        <v-col v-else></v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table :headers="headers" :items="organizationUsers">
            <template v-slot:[`item.checkbox`]="{ item }">
              <v-simple-checkbox v-model="item.checkbox"></v-simple-checkbox>
            </template>

            <template v-slot:[`item.validationStatus`]="{ item }">
              <template v-if="item.validationStatus == 'VALIDATED'">
                <v-chip style="background: #4caf50 !important" textColor="white" dark>
                  VALIDADO
                </v-chip>
              </template>

              <template v-else>
                <v-chip style="background: #dbdb03 !important" textColor="black" dark>
                  PENDIENTE
                </v-chip>
              </template>
            </template>

            <template v-slot:[`item.name`]="{ item }">
              <b>{{ item.name }}</b>
            </template>

            <template v-slot:[`item.firstLastname`]="{ item }">
              <b>{{ item.firstLastname }}</b>
            </template>

            <template v-slot:[`item.email`]="{ item }">
              <b>EMAIL - </b> {{ item.email }}
            </template>

            <template v-slot:[`item.position`]="{ item }">
              <b>CARGO - </b> {{ item.position }}
            </template>

            <template v-slot:[`item.permissionType`]="{ item }">
              <template v-if="item.permissionsType === 'EVENTS_ONLY'">
                <b>PERMISO - </b> Administrar eventos y cursos
              </template>
              <template v-else-if="item.permissionsType === 'MEMBERSHIPS_ONLY'">
                <b>PERMISO - </b> Administrar membresías
              </template>
              <template v-else-if="item.permissionsType === 'TOTAL_ACCESS'">
                <b>PERMISO - </b> Acceso total
              </template>
              <template v-else-if="item.permissionsType === 'READ_ONLY'">
                <b>PERMISO - </b> Solo lectura
              </template>
              <template v-else-if="item.permissionsType === 'REPORT_DOWNLOAD'">
                <b>PERMISO - </b> Descargar reportes
              </template>
            </template>

            <template v-slot:top>
              <!-- popup -->
              <v-dialog v-model="dialog" max-width="700px">
                <v-card>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-row>
                          <v-col cols="12" class="px-8 pt-5">
                            <h2 class="
                                wDarkerBlue--text
                                font-weight-black
                                tittlePermit
                              ">
                              Agregar / editar permiso
                            </h2>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" class="d-flex justify-left">
                            <span class="font-weight-bold bluetext titlepop">Datos del usuario</span>
                          </v-col>
                          <v-col cols="12" lg="6" xs="12">
                            <v-text-field label="Nombres" placeholder="Ej: Christian Israel" filled rounded
                              v-model="userData.name" :rules="nameRules"></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6" xs="12">
                            <v-text-field label="Apellidos" placeholder="Ej: Paliza Gutierrez" filled rounded
                              v-model="userData.firstLastname" :rules="firstLastnameRules"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field label="Posición" placeholder="Ej: Administrador" filled rounded
                              v-model="userData.position" :rules="positionRules"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="d-flex justify-left">
                            <span class="font-weight-bold bluetext titlepop">Datos de ingreso</span>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field label="Correo" placeholder="ej@gmail.com" filled rounded
                              v-model="userData.email" @keyup="_validateUser()" :rules="emailRules">
                            </v-text-field>
                          </v-col>
                          <!-- <v-col cols="12" lg="6" xs="12">
                            <v-text-field
                              label="Contraseña"
                              placeholder="Utiliza 8 caracteres, números y mayus."
                              filled
                              v-model="userData.password"
                              rounded
                              :rules="passwordRules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6" xs="12">
                            <v-text-field
                              label="Confirma contraseña"
                              placeholder="Utiliza 8 caracteres, números y mayus."
                              filled
                              rounded
                              v-model="userData.confirmPassword"
                              :rules="[
                                userData.password == userData.confirmPassword ||
                                  'La confirmación de contraseña no es correcta',
                              ]"
                            >
                            </v-text-field>
                          </v-col> -->
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="d-flex justify-left">
                            <span class="font-weight-bold bluetext titlepop">Asignar permisos</span>
                          </v-col>

                          <!-- Permiso -->
                          <v-col cols="12">
                            <v-row class="contentPermits">
                              <v-col cols="2" lg="1" xs="2">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                      info
                                    </v-icon>
                                  </template>
                                  <span>El usuario tiene acceso a visualizar, crear
                                    y editar en todas las áreas del
                                    tablero.</span>
                                </v-tooltip>
                              </v-col>
                              <v-col cols="5" lg="4" xs="5">
                                <span>Acceso total</span>
                              </v-col>
                              <v-col cols="2" lg="1" xs="2">
                                <v-switch color="accent" value="TOTAL_ACCESS" @change="(v) => setPermissionType(v)"
                                  v-model="userData.permissionsType">
                                </v-switch>
                              </v-col>
                            </v-row>
                          </v-col>
                          <!-- Permiso -->
                          <v-col cols="12">
                            <v-row class="contentPermits">
                              <v-col cols="2" lg="1" xs="2">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                      info
                                    </v-icon>
                                  </template>
                                  <span>El usuario tiene acceso únicamente a
                                    visualizar todas las áreas del
                                    tablero.</span>
                                </v-tooltip>
                              </v-col>
                              <v-col cols="5" lg="4" xs="5">
                                <span>Solo lectura de todas las áreas</span>
                              </v-col>
                              <v-col cols="2" lg="1" xs="2">
                                <v-switch color="accent" value="READ_ONLY" @change="(v) => setPermissionType(v)"
                                  v-model="userData.permissionsType">
                                </v-switch>
                              </v-col>
                            </v-row>
                          </v-col>
                          <!-- Permiso -->
                          <v-col cols="12">
                            <v-row class="contentPermits">
                              <v-col cols="2" lg="1" xs="2">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                      info
                                    </v-icon>
                                  </template>
                                  <span>El usuario tiene acceso a visualizar, crear
                                    y editar únicamente el módulo de
                                    eventos.</span>
                                </v-tooltip>
                              </v-col>
                              <v-col cols="5" lg="4" xs="5">
                                <span>Administrar eventos y cursos</span>
                              </v-col>
                              <v-col cols="2" lg="1" xs="2">
                                <v-switch color="accent" value="EVENTS_ONLY" @change="(v) => setPermissionType(v)"
                                  v-model="userData.permissionsType">
                                </v-switch>
                              </v-col>
                            </v-row>
                          </v-col>
                          <!-- Permiso -->
                          <v-col cols="12">
                            <v-row class="contentPermits">
                              <v-col cols="2" lg="1" xs="2">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                      info
                                    </v-icon>
                                  </template>
                                  <span>El usuario tiene acceso a visualizar, crear
                                    y editar únicamente el módulo de
                                    eventos.</span>
                                </v-tooltip>
                              </v-col>
                              <v-col cols="5" lg="4" xs="5">
                                <span>Administrar membresías</span>
                              </v-col>
                              <v-col cols="2" lg="1" xs="2">
                                <v-switch color="accent" value="MEMBERSHIPS_ONLY" @change="(v) => setPermissionType(v)"
                                  v-model="userData.permissionsType"></v-switch>
                              </v-col>
                            </v-row>
                          </v-col>
                          <!-- Permiso -->
                          <v-col cols="12">
                            <v-row class="contentPermits">
                              <v-col cols="2" lg="1" xs="2">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                      info
                                    </v-icon>
                                  </template>
                                  <span>
                                    El usuario tiene acceso a visualizar
                                    todos los módulos del tablero, además de
                                    descargar reportes.
                                  </span>
                                </v-tooltip>
                              </v-col>
                              <v-col cols="5" lg="4" xs="5">
                                <span>Descargar Reportes</span>
                              </v-col>
                              <v-col cols="2" lg="1" xs="2">
                                <v-switch color="accent" value="REPORT_DOWNLOAD" @change="(v) => setPermissionType(v)"
                                  v-model="userData.permissionsType"></v-switch>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="close()">
                      <v-icon class="bluetext"> mdi-chevron-left </v-icon>
                      <span> Volver </span>
                    </v-btn>

                    <v-btn color="#141d30" @click="save()" class="font-weight-bold textwhite spaceb"
                      :class="$vuetify.breakpoint.smAndUp ? 'rounded-xl' : ''">
                      <v-icon class="bluetext"> check </v-icon>
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">¿Estas seguro que quieres eliminar <br />
                    el permiso?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" text @click="closeDelete">Cancelar</v-btn>
                    <v-btn color="accent" text @click="deleteItemConfirm">Aceptar</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }" v-if="this.boolUserPermission === false">
              <v-icon small class="mr-2" @click="editItem(item)" :disabled="!user.permissions.Permission ||
              !user.permissions.Permission.update
              ">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item._id)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12" offset-md="1" md="10">
          <help-desk-card-list v-if="!loaderVisible" :items="questions"></help-desk-card-list>
        </v-col>
      </v-row>
      <v-snackbar :multi-line="true" :top="true" :right="true" :timeout="time" v-model="isVisible" :color="color"
        style="z-index: 249" rounded="xl" transition="slide-x-reverse-transition">
        <span class="subtitle-2 font-weight-bold">{{ text }}</span>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { validations } from "@/share/validations.js";
import Endpoints from "@/share/Endpoints";
import HelpDeskCardList from "@/components/shared/HelpDesk/HelpDeskCardList";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  components: {
    HelpDeskCardList,
  },
  computed: {
    ...mapState("loader", ["loaderVisible"]),
    ...mapState("user", ["user"]),
    ...mapState("organizationUsers", ["organizationUsers"]),
    ...mapState("notification", ["text", "color", "time", "visible"]),
    isVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.hide();
      },
    },
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      searchInfo: "",
      questions: [],
      headers: [
        { text: "", value: "checkbox", sortable: false },
        { text: "", value: "validationStatus", sortable: false },
        {
          text: " ",
          align: "start",
          value: "name",
          sortable: false,
        },
        { text: "", value: "firstLastname", sortable: false },
        { text: " ", value: "email", sortable: false },
        { text: " ", value: "position", sortable: false },
        { text: " ", value: "permissionType", sortable: false },
        { text: " ", value: "actions", sortable: false },
      ],
      userData: {
        id: "",
        name: "",
        firstLastname: "",
        position: "",
        permissionsType: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      orgUserName: "",
      boolEdit: false,
      organizationUsers: [],
      //VALIDACIONES
      nameRules: validations.nameValidation({ name: "nombre", required: true }),
      firstLastnameRules: validations.nameValidation({
        name: "primer apellido",
        required: true,
      }),
      positionRules: validations.nameValidation({
        name: "posicion",
        required: true,
      }),
      emailRules: validations.emailValidation(),
      passwordRules: validations.generalFValidation({
        required: true,
        minLength: 8,
        name: "contraseña",
      }),
      boolUserPermission: false,
      alertBool: false,

      computed: {
        formTitle() {
          return this.editedIndex === -1 ? "New Item" : "Edit Item";
        },
      },

      watch: {
        dialog(val) {
          val || this.close();
        },
        dialogDelete(val) {
          val || this.closeDelete();
        },
      },

      created() {
        this.initialize();
      },
    };
  },
  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show", "hide"]),
    ...mapActions("user", ["validateUser"]),
    ...mapActions("organization", [
      "createOrganizationUser",
      "getOrganizationUsers",
      "deleteOrganizationUser",
      "updateOrganizationUser",
      "getOrganizationUserByName",
    ]),

    isValid(permission) {
      return (
        permission.action === "create" && permission.subject === "Permission"
      );
    },

    async filtrarOrganizationUser() {
      this.organizationUsers = [];
      const response = await this.getOrganizationUserByName(this.orgUserName);

      if (response.status === 200) {
        response.user.forEach((ou) => {
          this.organizationUsers.push(ou);
        });
      }
    },

    async fetchOrganizationUsers() {
      this.loading();
      let response = await this.getOrganizationUsers();

      if (response.status == 200) {
        response.user.forEach((ou) => {
          this.organizationUsers.push(ou);
        });
        this.loaded();
      }
    },

    async refreshOrganizationUsers() {
      let response = await this.getOrganizationUsers();

      if (response.status == 200) {
        this.organizationUsers = []

        response.user.forEach((ou) => {
          this.organizationUsers.push(ou);
        });
      }
    },

    abrirModal() {
      this.dialog = true;
    },

    editItem(item) {
      this.dialog = true;
      this.userData = item;
      this.boolEdit = true;
    },

    deleteItem(item) {
      this.dialogDelete = true;
      this.userData.id = item;
    },

    async deleteItemConfirm() {
      this.loading();
      let id = this.userData.id;

      await this.deleteOrganizationUser(id);

      this.userData.id = "";

      this.loaded();

      this.show({
        text: "¡Permiso eliminado exitosamente!",
        color: "success",
      });

      // let organizationUsers = await this.getOrganizationUsers();

      // console.log(organizationUsers);

      // organizationUsers.user.forEach((ou) => {
      //   this.organizationUsers.push(ou);
      // });

      // this.$router.go(0)
      this.closeDelete()
      await this.refreshOrganizationUsers()
    },

    close() {
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    async _validateUser() {
      try {
        if (/.+@.+\..+/.test(this.userData.email)) {
          let response = await this.validateUser(this.userData.email);
          this.emailAvailable = response.status;
        }
      } catch (error) {
        console.log(error);
      }
    },

    setPermissionType(value) {
      this.userData.permissionsType = value;
    },

    async save() {
      this.loading();

      if (this.boolEdit === true) {
        let response = await this.updateOrganizationUser(this.userData);

        if (response.status === 200) {
          this.close();
          this.boolEdit = false;
          this.loaded();
          this.show({
            text: "¡Permiso editado exitosamente!",
            color: "success",
          });

          this.userData = {
            id: "",
            name: "",
            firstLastname: "",
            position: "",
            permissionsType: "",
            email: "",
            password: "",
            confirmPassword: "",
          };

          await this.refreshOrganizationUsers()
        }
      } else {
        let response = await this.createOrganizationUser(this.userData);

        if (response.status === true) {
          //this.organizationUsers.push(this.userData);
          this.close();
          this.loaded();
          this.show({
            text: "¡Permiso creado exitosamente!",
            color: "success",
          });
          this.userData = {
            id: "",
            name: "",
            firstLastname: "",
            position: "",
            permissionsType: "",
            email: "",
            password: "",
            confirmPassword: "",
          };
          await this.refreshOrganizationUsers()
        } else {
          this.close();
          this.loaded();
          this.show({
            text: "¡Ha ocurrido un problema al crear el permiso o el correo ya existe!",
            color: "error",
          });
        }
      }
    },
  },
  item: [
    {
      title: "Panel de inicio",
    },
  ],

  async mounted() {
    await this.fetchOrganizationUsers();
  },
};
</script>
<style scoped>
.rounded-search {
  border-radius: 0px 24px 24px 0px !important;
}

.v-btn--is-elevated {
  box-shadow: none !important;
}

.v-chip.v-size--default {
  border-radius: 4px;
  background-color: #a5deee !important;
  border-color: #a5deee !important;
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
}

.bluetext {
  color: #141d30;
}

.bluebg {
  background-color: #141d30;
}

.textwhite {
  color: #ffffff;
}

.spaceb {
  padding-right: 45px !important;
  margin-left: 6%;
  font-size: 12px;
}

.titlepop {
  font-size: 15px;
}

.contentPermits {
  display: flex !important;
  align-items: center !important;
  height: 100px;
}

.v-dialog>.v-card>.v-card__text {
  padding: 0 50px 50px !important;
}

.tittlePermit {
  margin-top: 4%;
}
</style>