<template>
  <v-tooltip top>
    <template #activator="{ on, attrs}">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        :color="color"
        :disabled="disabled"
        @click="$emit('click')"
      >
        <v-icon>
          <slot>
            mdi-plus
          </slot>
        </v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>
<script>

export default {
  props: {
    color: {
      type: String,
      default: 'blue'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      required: true,
      default: ''
    } 
  }
};
</script>
