var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"mt-5",attrs:{"justify":"center"}},[_c('ModalTemplate',{attrs:{"open":_vm.showModal,"primaryBtnProps":{
        text: 'Guardar',
        show: true,
      },"secondaryBtnProps":{
        text: 'Cerrar',
        show: true,
        class: 'mr-2',
      }},on:{"close":_vm.handleClose,"continue":_vm.handleContinue},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-stretch"},[_c('p',{staticClass:"text-h3 align-self-center"},[_vm._v(" "+_vm._s(_vm.editMode ? "Editar Ejecutivo" : "Crear Nuevo Ejecutivo")+" ")]),_c('v-form',{ref:"executiveForm"},[_c('v-text-field',{attrs:{"label":"Nombre","filled":"","rounded":""},model:{value:(_vm.executive.name),callback:function ($$v) {_vm.$set(_vm.executive, "name", $$v)},expression:"executive.name"}}),_c('v-text-field',{attrs:{"label":"Apellido","filled":"","rounded":""},model:{value:(_vm.executive.lastName),callback:function ($$v) {_vm.$set(_vm.executive, "lastName", $$v)},expression:"executive.lastName"}}),_c('v-text-field',{attrs:{"label":"Correo Electronico","filled":"","rounded":""},model:{value:(_vm.executive.email),callback:function ($$v) {_vm.$set(_vm.executive, "email", $$v)},expression:"executive.email"}})],1)],1)]},proxy:true}])})],1),_c('v-row',[_c('v-spacer'),_c('ActionButton',{attrs:{"disabled":!_vm.user.permissions.Permission || !_vm.user.permissions.Permission.create},on:{"click":function($event){(_vm.showModal = true), (_vm.editMode = false)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Nuevo Ejecutivo ")],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.executives && _vm.executives.length && _vm.executives.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.executives,"item-key":"name"},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item,index){return _c('tr',{key:index,staticClass:"text-center",class:[
                _vm.$vuetify.breakpoint.xsOnly
                  ? 'v-data-table__mobile-table-row'
                  : '',
              ]},[_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.headers[0].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                      ? 'v-data-table__mobile-row__cell'
                      : ''},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.headers[1].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                      ? 'v-data-table__mobile-row__cell'
                      : ''},[_vm._v(" "+_vm._s(item.lastName)+" ")])]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.headers[2].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                      ? 'v-data-table__mobile-row__cell'
                      : ''},[_vm._v(" "+_vm._s(item.email)+" ")])]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.headers[3].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                      ? 'v-data-table__mobile-row__cell'
                      : 'd-flex justify-center'},[(item.status === 'ACTIVE')?_c('div',{staticClass:"d-inline-block blue lighten-3 rounded-lg"},[_c('span',{staticClass:"text-caption ma-1"},[_vm._v("ACTIVO")])]):_c('div',{staticClass:"d-inline-block red lighten-3 rounded-lg"},[_c('span',{staticClass:"text-caption ma-1"},[_vm._v("ELIMINADO")])])])]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.headers[4].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                      ? 'v-data-table__mobile-row__cell'
                      : ''},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('icon-button',{attrs:{"color":"red","tooltip":"Eliminar Ejecutivo","disabled":!_vm.user.permissions.Permission || !_vm.user.permissions.Permission.delete},on:{"click":function($event){(_vm.itemToDelete = item._id),
                          (_vm.deleteExecutiveDialog = true)}}},[_vm._v(" mdi-delete-outline ")]),_c('icon-button',{attrs:{"color":"accent","tooltip":"Editar Ejecutivo","disabled":!_vm.user.permissions.Permission || !_vm.user.permissions.Permission.update},on:{"click":function($event){(_vm.showModal = true),
                          (_vm.editMode = true),
                          (_vm.executive = item)}}},[_vm._v(" mdi-pencil-outline ")])],1)],1)])])}),0)]}}],null,false,3295319389)}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.deleteExecutiveDialog),callback:function ($$v) {_vm.deleteExecutiveDialog=$$v},expression:"deleteExecutiveDialog"}},[_c('v-card',[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"mt-8 d-flex justify-center",attrs:{"cols":"10","md":"7"}},[_c('v-img',{attrs:{"src":require("@/assets/images/weChamber/logo-WC-main-nav-drawer.svg"),"max-width":"220","height":"auto","contain":""}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center font-weight-bold headline mb-0"},[_vm._v(" Eliminar Ejecutivo ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"title text-center mb-0"},[_vm._v(" ¿Está seguro que quiere eliminar el ejecutivo seleccionado? ")]),_c('p',{staticClass:"title text-center"},[_vm._v(" Los datos de no podrán ser recuperados. ")])])],1),_c('v-row',{staticClass:"d-flex pb-2 mt-5",class:_vm.$vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'},[_c('v-btn',{staticClass:"font-weight-bold black--text mx-2",attrs:{"color":"grey lighten-3","rounded":"","elevation":"0"},on:{"click":function($event){(_vm.deleteExecutiveDialog = false), (_vm.itemToDelete = '')}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"font-weight-bold black--text mx-2",attrs:{"color":"accent","rounded":"","elevation":"0"},on:{"click":function($event){return _vm.removeExecutive()}}},[_vm._v(" Aceptar ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }