<template>
  <v-container>
    <v-row class="mt-5" justify="center">
      <ModalTemplate
        @close="handleClose"
        @continue="handleContinue"
        :open="showModal"
        :primaryBtnProps="{
          text: 'Guardar',
          show: true,
        }"
        :secondaryBtnProps="{
          text: 'Cerrar',
          show: true,
          class: 'mr-2',
        }"
      >
        <template #content>
          <div class="d-flex flex-column align-stretch">
            <p class="text-h3 align-self-center">
              {{ editMode ? "Editar Ejecutivo" : "Crear Nuevo Ejecutivo" }}
            </p>
            <v-form ref="executiveForm">
              <v-text-field
                v-model="executive.name"
                label="Nombre"
                filled
                rounded
              />
              <v-text-field
                v-model="executive.lastName"
                label="Apellido"
                filled
                rounded
              />
              <v-text-field
                v-model="executive.email"
                label="Correo Electronico"
                filled
                rounded
              />
            </v-form>
          </div>
        </template>
      </ModalTemplate>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <ActionButton
        @click="(showModal = true), (editMode = false)"
        :disabled="!user.permissions.Permission || !user.permissions.Permission.create"
      >
        <v-icon>mdi-plus</v-icon>
        Nuevo Ejecutivo
      </ActionButton>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="executives"
          item-key="name"
          class="elevation-1"
          v-if="executives && executives.length && executives.length > 0"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="index"
                class="text-center"
                :class="[
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-table-row'
                    : '',
                ]"
              >
                <td
                  :class="
                    $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                  "
                >
                  <div
                    class="
                      font-weight-bold
                      mr-2
                      v-data-table__mobile-row__header
                      d-flex d-sm-none
                    "
                  >
                    {{ headers[0].text }}
                  </div>
                  <div
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row__cell'
                        : ''
                    "
                  >
                    {{ item.name }}
                  </div>
                </td>
                <td
                  :class="
                    $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                  "
                >
                  <div
                    class="
                      font-weight-bold
                      mr-2
                      v-data-table__mobile-row__header
                      d-flex d-sm-none
                    "
                  >
                    {{ headers[1].text }}
                  </div>
                  <div
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row__cell'
                        : ''
                    "
                  >
                    {{ item.lastName }}
                  </div>
                </td>
                <td
                  :class="
                    $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                  "
                >
                  <div
                    class="
                      font-weight-bold
                      mr-2
                      v-data-table__mobile-row__header
                      d-flex d-sm-none
                    "
                  >
                    {{ headers[2].text }}
                  </div>
                  <div
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row__cell'
                        : ''
                    "
                  >
                    {{ item.email }}
                  </div>
                </td>

                <td
                  :class="
                    $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                  "
                >
                  <div
                    class="
                      font-weight-bold
                      mr-2
                      v-data-table__mobile-row__header
                      d-flex d-sm-none
                    "
                  >
                    {{ headers[3].text }}
                  </div>
                  <div
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row__cell'
                        : 'd-flex justify-center'
                    "
                  >
                    <div
                      class="d-inline-block blue lighten-3 rounded-lg"
                      v-if="item.status === 'ACTIVE'"
                    >
                      <span class="text-caption ma-1">ACTIVO</span>
                    </div>
                    <div class="d-inline-block red lighten-3 rounded-lg" v-else>
                      <span class="text-caption ma-1">ELIMINADO</span>
                    </div>
                  </div>
                </td>
                <td
                  :class="
                    $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                  "
                >
                  <div
                    class="
                      font-weight-bold
                      mr-2
                      v-data-table__mobile-row__header
                      d-flex d-sm-none
                    "
                  >
                    {{ headers[4].text }}
                  </div>
                  <div
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row__cell'
                        : ''
                    "
                  >
                    <v-row class="d-flex justify-center">
                      <icon-button
                        color="red"
                        @click="
                          (itemToDelete = item._id),
                            (deleteExecutiveDialog = true)
                        "
                        tooltip="Eliminar Ejecutivo"
                        :disabled="!user.permissions.Permission || !user.permissions.Permission.delete"
                      >
                        mdi-delete-outline
                      </icon-button>
                      <icon-button
                        color="accent"
                        @click="
                          (showModal = true),
                            (editMode = true),
                            (executive = item)
                        "
                        tooltip="Editar Ejecutivo"
                        :disabled="!user.permissions.Permission || !user.permissions.Permission.update"
                      >
                        mdi-pencil-outline
                      </icon-button>
                    </v-row>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="deleteExecutiveDialog" width="600">
      <v-card>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="10" md="7" class="mt-8 d-flex justify-center">
              <v-img
                class=""
                src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg"
                max-width="220"
                height="auto"
                contain
              ></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mt-2">
              <p class="text-center font-weight-bold headline mb-0">
                Eliminar Ejecutivo
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p class="title text-center mb-0">
                ¿Está seguro que quiere eliminar el ejecutivo seleccionado?
              </p>
              <p class="title text-center">
                Los datos de no podrán ser recuperados.
              </p>
            </v-col>
          </v-row>
          <v-row
            class="d-flex pb-2 mt-5"
            :class="
              $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
            "
          >
            <v-btn
              class="font-weight-bold black--text mx-2"
              color="grey lighten-3"
              rounded
              elevation="0"
              @click="(deleteExecutiveDialog = false), (itemToDelete = '')"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="font-weight-bold black--text mx-2"
              color="accent"
              rounded
              elevation="0"
              @click="removeExecutive()"
            >
              Aceptar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ActionButton from "../../../../components/shared/ActionButton.vue";
import ModalTemplate from "../../../../components/shared/ModalTemplate.vue";
import IconButton from "@/components/shared/IconButton";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  components: {
    ActionButton,
    ModalTemplate,
    IconButton,
  },
  data() {
    return {
      executives: [],
      showModal: false,
      editMode: false,
      executive: {
        name: "",
        lastName: "",
        email: "",
        branch: "",
      },
      branches: [],
      headers: [
        { text: "Nombre", value: "name", align: "center" },
        { text: "Apellido", value: "lastname", align: "center" },
        { text: "Email", value: "email", align: "center" },
        { text: "Estado", value: "status", align: "center" },
        { text: "Acciones", value: "actions", align: "center" },
      ],
      deleteExecutiveDialog: false,
      itemToDelete: "",
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapActions("executives", [
      "getExcecutives",
      "createExecutive",
      "deleteExecutive",
      "updateExecutive",
    ]),
    ...mapActions("branches", ["getBranches"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    handleClose() {
      this.showModal = false;
      this.executive = {
        name: "",
        lastName: "",
        email: "",
      };
    },
    async handleContinue() {
      if (this.editMode) {
        await this.UpdateExecutive(this.executive);
      } else {
        await this.newExecutive();
      }
      this.fetchExecutives();
    },
    async fetchExecutives() {
      try {
        this.loading();
        const response = await this.getExcecutives();
        this.executives = response.data.executives;
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
      }
    },
    async newExecutive() {
      try {
        this.loading;
        const response = await this.createExecutive(this.executive);
        this.executives.push(response.executive);
        this.handleClose();
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
      }
    },
    async removeExecutive() {
      try {
        this.loading();
        const response = await this.deleteExecutive(this.itemToDelete);
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
        this.itemToDelete = "";
        this.deleteExecutiveDialog = false;
      }
    },

    async UpdateExecutive(executive) {
      try {
        this.loading();
        await this.updateExecutive(executive);
        this.handleClose();
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
      }
    },
  },
  created() {
    this.fetchExecutives();
  },
};
</script>

<style>
</style>