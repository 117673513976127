<template>
  <v-container fluid>
    <v-card color="px-0 px-md-5 py-7 rounded-xl elevation-0">
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab href="#permits">Permisos</v-tab>
            <v-tab href="#roles">Ejecutivos</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="permits">
              <Permits/>
            </v-tab-item>
            <v-tab-item value="roles">
              <Roles/>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import Permits from './tabs/Permits';
import Roles from './tabs/Roles';

export default {
  components: {
    Permits,
    Roles,
  },
  data: () => ({
    tab: "roles",
  }),
};
</script>
<style scoped>
.rounded-search {
  border-radius: 0px 24px 24px 0px !important;
}

.theme--light.v-tabs>.v-tabs-bar {
  background-color: transparent !important;
}
</style>